
import {
  IonInput,
  IonContent,
  IonPage,
  alertController,
  loadingController,
  IonSpinner,
  IonRefresher,
  IonRefresherContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonTextarea,
  modalController,
  IonButtons,
  IonButton,
} from "@ionic/vue";
import { defineComponent, onMounted, onUnmounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import SearchAddress from "../../../../components/SearchAddress.vue";
import Empty from "../../../../components/Empty.vue";
import { Analytics } from "../../../../common/analytics";

declare global {
  interface Window {
    daum: {
      Postcode: any;
    };
  }
}

/**
 * services
 */
import services from "../../../../services";

export default defineComponent({
  components: {
    IonInput,
    IonContent,
    IonPage,
    IonSpinner,
    Empty,
    IonRefresher,
    IonRefresherContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonBackButton,
    IonTextarea,
    IonButtons,
    IonButton,
  },
  props: {
    // postId
    id: {
      type: [Number],
      default: () => null,
    },
    level: {
      type: [Number],
      default: () => 1,
    },
  },
  setup(props) {
    const router = useRouter();
    const screenName = `PAGE-MISSION_${props.id}_APPLICATION`;
    const analytics = new Analytics();
    const state = reactive({
      tel: {
        isSend: false,
        auth: false,
        value: "",
        code: "",
        second: 0,
        timer: window.setTimeout(() => {
          console.log("");
        }, 0),
      },
      mission: {
        loading: true,
        data: {
          id: 0,
          complete: 0,
          isUserReview: 0,
          content: {
            guide: "",
            reward: [
              {
                locationTel: "",
              },
            ],
          },
        },
      },
      form: {
        score: 100,
        review: "",
        code: "",
        name: "",
        address: "",
        addressLine1: "",
        addressLine2: "",
        zip: "",
        tel: "",
        deliveryComment: "",
        /*
        name:'',
        address:'',
        tel:'',
        deliveryComment:'',
        addr:'',
        zip:''
        */
      },
      isReward: false,
      applicationMeans: "",
      contentLoading: true,
    });
    // message: any, callback: any = null
    const presentAlert = async (message: string, callback: any = null) => {
      const alert = await alertController.create({
        header: "알림",
        backdropDismiss: false,
        message: message,
        buttons: [
          {
            text: "확인",
            handler: () => {
              if (callback) {
                callback();
              }
            },
          },
        ],
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    };

    const embedref = ref(null);
    console.log(embedref);

    const onSubmit = async () => {
      const address = state.form.addressLine1 + " " + state.form.addressLine2;

      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        message: "미션을 완료하는 중입니다.",
      });
      await loading.present();

      const params = {
        missionId: state.mission.data.id,
        name: state.form.name,
        address: address,
        tel: state.form.tel,
        deliveryComment: state.form.deliveryComment,
        code: state.form.code,
        authTel: state.tel.value,
        lv: props.level,
      };

      if (state.applicationMeans == "delivery") {
        delete params.code;
      } else if (state.applicationMeans == "code") {
        delete params.name;
        delete params.address;
        delete params.tel;
        delete params.authTel;
        delete params.deliveryComment;
      } else {
        delete params.code;
        delete params.name;
        delete params.address;
        delete params.tel;
        delete params.authTel;
        delete params.deliveryComment;
      }

      await services.missionCompleteAdd(params).then(
        (response) => {
          if (response.status == 204) {
            if (state.form.review != "") {
              const params = {
                missionId: state.mission.data.id,
                content: state.form.review,
                score: state.form.score,
              };
              services.missionReviewAdd(params).then(
                (response) => {
                  const { data } = response;
                  console.log(response);
                  console.log(data);
                  loading.dismiss();
                },
                (error) => {
                  let message = "";
                  try {
                    message = error.response.data.message;
                  } catch (e) {
                    message = "";
                    console.log(e);
                  }
                  setTimeout(function () {
                    loading.dismiss();
                    presentAlert(message);
                  }, 1000);
                }
              );
            }
            setTimeout(function () {
              loading.dismiss();

              let message = "";
              if (state.applicationMeans == "delivery") {
                message = "리워드 신청 및 미션완료 되었습니다.";
              } else if (state.applicationMeans == "code") {
                message = "인증 및 미션완료 되었습니다.";
              } else {
                message = "미션완료 되었습니다.";
              }

              state.mission.data.complete = 1;
            }, 1000);
          } else {
            setTimeout(function () {
              loading.dismiss();
            }, 1000);
          }
        },
        (error) => {
          let message = "";
          try {
            message = error.response.data.message;
          } catch (e) {
            message = "";
            console.log(e);
          }
          setTimeout(function () {
            loading.dismiss();
            presentAlert(message);
          }, 1000);
        }
      );
    };

    const fetch = async (event: any = null, loadMore = false) => {
      if (!loadMore) {
        await services
          .missionFindones(props.id, {
            level: props.level,
          })
          .then(
            (response) => {
              const { data } = response;
              console.log(data);
              if (event) {
                if (event.type == "ion-refresh") {
                  state.mission.data.id = 0;
                }
              }

              if (data.completeStampCount < data.acquisitionStampCount) {
                data.acquisitionStampCount = data.completeStampCount;
              }

              state.isReward = false;
              if (data.reward == 1) {
                state.isReward = true;
              } else if (data.reward == 2) {
                if (data.rewardType == 1) {
                  state.isReward = true;
                  state.applicationMeans = "code";
                } else if (data.rewardType == 2) {
                  state.isReward = true;
                  state.applicationMeans = "delivery";
                } else if (data.rewardType == 3) {
                  state.isReward = true;
                  state.applicationMeans = "delivery";
                } else if (data.rewardType == 0) {
                  state.isReward = true;
                }
              }

              if (state.applicationMeans != "delivery") {
                state.tel.auth = true;
              }

              /*
              <a href="" @click.prevent.stop="onApplicationMeans('delivery')">배송수령</a>
              <a href="" @click.prevent.stop="onApplicationMeans('code')">직접수령</a>

            <template v-if="state.mission.data.rewardType == 1">
              <p class="gift_apply_text other">
                리워드(선물)를 받기 위해 <span class="box_black">담당자의 인증이 필요합니다.</span>
              </p>
              <p class="gift_apply_text">
                리워드(선물)를 배포하는 곳으로 가셔서<span class="box_black">담당자에게 해당 화면을 보여주세요.</span>
              </p>
            </template>
            <template v-else-if="state.mission.data.rewardType == 2">
              <p class="gift_apply_text other">
                리워드(선물)를 받기 위해 <span class="box_black">주소 및 연락처를 입력해 주세요.</span>
              </p>
              <p class="gift_apply_text">
                리워드(선물)는 일괄배송 되며,<span class="box_black">선물 소진 시, 다른 리워드(선물)로 대체하여</span>  배송될 수 있습니다.
              </p>
            </template>
            <template v-else-if="state.mission.data.rewardType == 3">
            </template>
            */

              state.mission.data = data;
              state.mission.loading = false;
            },
            (error) => {
              let message = "";
              try {
                message = error.response.data.message;
              } catch (e) {
                message = "";
                console.log(e);
              }
              console.log(message);
              if (event) {
                if (event.type == "ion-refresh") {
                  state.mission.data.id = 0;
                }
              }
              state.mission.loading = false;
            }
          );
      }

      setTimeout(() => {
        if (event) {
          event.target.complete();
        }

        state.contentLoading = false;
      }, 500);
    };

    const onLinkSearchAddr = async () => {
      const modal = await modalController.create({
        component: SearchAddress,
      });
      modal.onDidDismiss().then((response) => {
        const { data } = response;
        console.log(data);
        if (data) {
          state.form.addressLine1 = data.address;
          state.form.addressLine2 = data.addressDetail;
          state.form.zip = data.zip;
        }
      });
      return modal.present();
    };

    // 새로고침
    const onRefresh = (event: any) => {
      state.mission.loading = false;

      fetch(event);
    };

    const onApplicationMeans = (applicationMeans: string) => {
      if (state.applicationMeans != applicationMeans) {
        state.applicationMeans = applicationMeans;
      }
    };

    const onClose = async () => {
      const n = window.history.length - 2;
      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        message: `리뷰 작성중..`,
      });
      if (state.form.review != "") {
        const params = {
          missionId: state.mission.data.id,
          content: state.form.review,
          score: state.form.score,
        };
        await services.missionReviewAdd(params).then(
          (response) => {
            loading.dismiss();
            state.form.review = "";
            if (response.status == 200) {
              setTimeout(function () {
                presentAlert("작성됐습니다. 홈으로 이동할까요?", async () => {
                  window.history.length > 1 ? router.go(-n) : router.push("/");
                });
              }, 100);
            }
          },
          (error) => {
            loading.dismiss();
            let message = "";
            try {
              message = error.response.data.message;
            } catch (e) {
              message = "";
              console.log(e);
            }
            console.log(message);
            // openToast(message);
          }
        );
      } else {
        window.history.length > 1 ? router.go(-n) : router.push("/");
      }
    };

    const telAuthTimer = async () => {
      clearTimeout(state.tel.timer);
      if (0 < state.tel.second) {
        state.tel.second = state.tel.second - 1;

        state.tel.timer = setTimeout(function () {
          telAuthTimer();
        }, 1000);
      } else {
        if (!state.tel.auth) {
          state.tel.isSend = false;
        }
      }
    };

    const onTelAuthMessageSubmit = async () => {
      console.log(state.form);

      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        message: "잠시 기다려주세요.",
      });
      await loading.present();

      const params = {
        missionId: state.mission.data.id,
        tel: state.tel.value,
      };

      await services.missionTelAdd(params).then(
        (response) => {
          if (response.status == 204) {
            setTimeout(function () {
              loading.dismiss();
              state.tel.isSend = true;
              state.tel.second = 60;
              clearTimeout(state.tel.timer);
              telAuthTimer();
            }, 1000);
          } else {
            setTimeout(function () {
              loading.dismiss();
            }, 1000);
          }
        },
        (error) => {
          let message = "";
          try {
            message = error.response.data.message;
          } catch (e) {
            message = "";
            console.log(e);
          }
          setTimeout(function () {
            loading.dismiss();
            presentAlert(message);
          }, 1000);
        }
      );
    };

    const onTelAuthReMessageSubmit = async () => {
      console.log(state.form);

      if (0 < state.tel.second) {
        presentAlert("60초후 재전송 가능합니다.");
        return;
      }

      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        message: "잠시 기다려주세요.",
      });
      await loading.present();

      const params = {
        missionId: state.mission.data.id,
        tel: state.tel.value,
      };

      await services.missionTelAdd(params).then(
        (response) => {
          if (response.status == 204) {
            setTimeout(function () {
              loading.dismiss();
              state.tel.isSend = true;
              state.tel.second = 60;
              clearTimeout(state.tel.timer);
              telAuthTimer();
            }, 1000);
          } else {
            setTimeout(function () {
              loading.dismiss();
            }, 1000);
          }
        },
        (error) => {
          let message = "";
          try {
            message = error.response.data.message;
          } catch (e) {
            message = "";
            console.log(e);
          }
          setTimeout(function () {
            loading.dismiss();
            presentAlert(message);
          }, 1000);
        }
      );
    };

    const onTelAuthSubmit = async () => {
      console.log(state.form);

      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        message: "잠시 기다려주세요.",
      });
      await loading.present();

      const params = {
        missionId: state.mission.data.id,
        tel: state.tel.value,
        code: state.tel.code,
      };

      await services.missionTelAdd(params).then(
        (response) => {
          if (response.status == 204) {
            setTimeout(function () {
              loading.dismiss();
              clearTimeout(state.tel.timer);
              presentAlert("휴대폰 번호가 확인되었습니다.", async () => {
                state.tel.auth = true;
              });
            }, 1000);
          } else {
            setTimeout(function () {
              loading.dismiss();
            }, 1000);
          }
        },
        (error) => {
          let message = "";
          try {
            message = error.response.data.message;
          } catch (e) {
            message = "";
            console.log(e);
          }
          setTimeout(function () {
            loading.dismiss();
            presentAlert(message);
          }, 1000);
        }
      );
    };

    // onUnmounted
    onUnmounted(() => {
      clearTimeout(state.tel.timer);
    });

    // onMounted
    onMounted(() => {
      state.form.review = "";
      state.form.score = 100;
      fetch();
      console.log("onMounted");
      analytics.setScreenName(screenName);
    });

    return {
      state,
      onSubmit,
      onRefresh,
      onApplicationMeans,
      onClose,
      onTelAuthReMessageSubmit,
      onTelAuthMessageSubmit,
      onTelAuthSubmit,
      onLinkSearchAddr,
    };
  },
});
