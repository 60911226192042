
import {
  IonSpinner,
  IonIcon,
  IonRefresher,
  IonRefresherContent,
  IonButtons,
  IonButton,
  IonTitle,
  IonToolbar,
  IonHeader,
  IonInput,
  IonContent,
  IonPage,
  modalController,
  alertController,
} from "@ionic/vue";
import { defineComponent, onMounted, reactive } from "@vue/runtime-core";
import services from "../services";
import { Analytics } from "../common/analytics";
import SearchAddressComplete from "./SearchAddressComplete.vue";

export default defineComponent({
  components: {
    IonSpinner,
    IonIcon,
    IonRefresher,
    IonRefresherContent,
    IonButtons,
    IonButton,
    IonTitle,
    IonToolbar,
    IonHeader,
    IonInput,
    IonContent,
    IonPage,
  },
  setup() {
    const screenName = "MODAL-SEARCH_ADDRESS";
    const analytics = new Analytics();
    const state = reactive({
      contentLoading: false,
      search: "",
      important: "",
      size: 20,
      page: 1,
      result: {
        items: [],
        totalItems: 1,
        totalPages: 1,
      },
      address: {
        address: "",
        addressDetail: "",
        zip: "",
      },
    });

    const presentAlert = async (message: string, callback: any = null) => {
      const alert = await alertController.create({
        header: "알림",
        backdropDismiss: false,
        message: message,
        buttons: [
          {
            text: "확인",
            handler: () => {
              if (callback) {
                callback();
              }
            },
          },
        ],
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    };

    const fetch = async (page: any, important: any = null) => {
      if (important) {
        state.important = important;
      }

      state.contentLoading = true;
      state.page = page;
      const params = {
        important: state.important,
        page: page,
        size: state.size,
      };
      await services.addressFindAll(params).then(
        (response) => {
          const { data } = response;
          state.result.items = data.items;
          state.result.totalItems = data.totalItems;
          state.result.totalPages = data.totalPages;

          state.contentLoading = false;
        },
        (error) => {
          let message = "";
          try {
            message = error.response.data.message;
          } catch (e) {
            message = "";
            console.log(e);
          }
          setTimeout(function () {
            state.contentLoading = false;
            presentAlert(message);
          }, 1000);
        }
      );
    };

    const onSearch = () => {
      if (state.search.replace(/ /g, "").length == 0) {
        presentAlert("검색어가 입력되지 않았습니다.");
      } else {
        fetch(1, state.search);
      }
    };

    const nextPage = () => {
      if (state.page < state.result.totalPages) {
        state.page += 1;
      }
      fetch(state.page);
    };
    const prevPage = () => {
      if (state.page > 1) {
        state.page -= 1;
      }
      fetch(state.page);
    };
    const onDismissModal = async () => {
      modalController.dismiss();
    };
    const onCompleteLink = async (roadAddr: any, zipNo: any) => {
      const modal = await modalController.create({
        component: SearchAddressComplete,
        componentProps: {
          address: roadAddr,
          zip: zipNo,
        },
      });
      modal.onDidDismiss().then((response) => {
        const { data } = response;
        state.address.addressDetail = data.addressDetail;
        modalController.dismiss({
          address: data.address,
          addressDetail: state.address.addressDetail,
          zip: data.zip,
        });
      });
      return modal.present();
    };
    onMounted(() => {
      analytics.setScreenName(screenName);
    });
    return {
      state,
      onCompleteLink,
      onSearch,
      nextPage,
      prevPage,
      onDismissModal,
    };
  },
});
