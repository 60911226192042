
import {
  IonInput,
  IonContent,
  IonPage,
  IonToolbar,
  IonSpinner,
  IonRefresher,
  IonRefresherContent,
  modalController,
} from "@ionic/vue";
import { defineComponent, onMounted, reactive } from "@vue/runtime-core";

export default defineComponent({
  name: "SearchAddressComplete",
  components: {
    IonInput,
    IonContent,
    IonPage,
    IonToolbar,
    IonSpinner,
    IonRefresher,
    IonRefresherContent,
  },
  props: {
    address: {
      type: String,
      default: null,
      description: "address result",
    },
    zip: {
      type: Number,
      default: null,
      description: "zipNumber",
    },
  },
  setup(props) {
    const state = reactive({
      contentLoading: false,
      addressResult: "",
      addressDetail: "",
      zip: 0,
    });

    const fetch = () => {
      state.addressResult = props.address;
      state.zip = props.zip;
    };

    const onDismissModal = async () => {
      modalController.dismiss({
        address: state.addressResult,
        addressDetail: state.addressDetail,
        zip: state.zip,
      });
    };

    onMounted(() => {
      fetch();
    });

    return {
      state,
      onDismissModal,
    };
  },
});
